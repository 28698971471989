import React, { Component, Fragment } from 'react';
import MediaButton from '../../components/MediaButton';
import { ReactComponent as Headphones } from '../../icons/headphones.svg';
import { ReactComponent as PlayButton } from '../../icons/play-arrow.svg';
import { ReactComponent as Kanguka } from '../../icons/kanguka-logo.svg';
import { ReactComponent as FacebookButton } from '../../icons/icons8-facebook.svg';
import { ReactComponent as MediumButton } from '../../icons/icons8-medium.svg';
import { ReactComponent as SpotifyButton } from '../../icons/icons8-spotify.svg';
import { ReactComponent as TwitterButton } from '../../icons/icons8-twitter.svg';
import { ReactComponent as YoutubeButton } from '../../icons/icons8-youtube.svg';
import { ReactComponent as InstagramButton } from '../../icons/icons8-instagram.svg';
import { ReactComponent as PodcastCZButton } from '../../icons/icons8-podcast_cz.svg';
import { ReactComponent as LinkedInButton } from '../../icons/icons8-linkedin.svg';
import './style.scss';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-N9ZZMSEQLH";

class Home extends Component {
  componentDidMount() {
    requestAnimationFrame(() => {
      [...document.querySelector('.home').querySelectorAll('.hidden')].map((elmt) => elmt.classList.add('active'));
    });
  }

  shouldComponentUpdate(prevProps) {
    return prevProps.active !== this.props.active;
  }

  gaTrack = (category, label) => {
    ReactGA.event({
        category: category,
        action: 'Click',
        label: label,
    });
  };

  render() {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: "/landingpage", title: "Landing Page" });
    return (
      <Fragment>
        <h1 className="title hidden"> Slyš, věř a buď uzdraven!</h1>
        <p className="subtitle hidden">Papišek.cz</p>
        <Headphones className="icon hidden" width="80" fill="#ccc" 
          active={true}
          onClick={this.props.onStartClick}/>
        
        <MediaButton
          className="hidden"
          tabEnabled={this.props.active}
          name="button show playlist"
          active={true}
          onClick={this.props.onStartClick}
          icon={<PlayButton width={24} />}
        />
        <footer className="footer">
        <button type="button" tabIndex="0" name="Facebook" aria-label="Facebook" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "Facebook"); window.open("https://www.facebook.com/profile.php?id=61556079492991")}}><FacebookButton/></button>
        <button type="button" tabIndex="0" name="Youtube" aria-label="Youtube" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "Youtube"); window.open("https://www.youtube.com/channel/UCcg9CwDRYtlLyikixJ9_t4g")}}><YoutubeButton/></button>
        <button type="button" tabIndex="0" name="Twitter" aria-label="Twitter" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "Twitter"); window.open("https://twitter.com/papisekcz")}}><TwitterButton/></button>
        <button type="button" tabIndex="0" name="Spotity" aria-label="Spotity" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "Spotify"); window.open("https://podcasters.spotify.com/pod/show/papisek")}}><SpotifyButton/></button>
        <button type="button" tabIndex="0" name="Podcast CZ" aria-label="Podcast CZ" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "Podcast CZ"); window.open("https://ceskepodcasty.cz/podcast/slys-ver-a-bud-uzdraven")}}><PodcastCZButton/></button>
        <button type="button" tabIndex="0" name="Medium" aria-label="Medium" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "Medium"); window.open("https://medium.com/@papisek")}}><MediumButton/></button>
        <button type="button" tabIndex="0" name="Linkedin" aria-label="Linkedin" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "LinkedIn"); window.open("https://www.linkedin.com/company/papisek")}}><LinkedInButton/></button>
        <a href="https://www.youtube.com/@KANGUKAFRANCAIS" tabIndex={this.props.active ? "0" : "-1"} target="_blank" rel="noopener noreferrer" className="soundcloud"><Kanguka aria-label="Kanguka" fill="#b9b9b9" width={64}/></a>
        </footer>
        <footer className="footer">
          <span className="teaser">Skutečná svědectví z celého světa o zázračném vyléčení rakoviny, cukrovky atd. pouze vírou v Ježíše Krista, posloucháním audia z domova.</span>
        </footer>
      </Fragment>
    );
  }
}

export default Home;
